import { useState, useEffect } from 'react';

const LOCAL_STORAGE_KEY = 'ga_client_id';

const getClientIdFromCookies = (): string | null => {
  const gaCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('_ga='));

  if (gaCookie) {
    const parts = gaCookie.split('.');
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return parts.length > 2 ? `${parts[2]}.${parts[3]}` : null;
  }

  return null;
};

export const getClientId = async (): Promise<string> => {
  const storedClientId = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (storedClientId) {
    return storedClientId;
  }

  return new Promise((resolve, reject) => {
    if (!window.gtag) {
      reject(new Error('Google Analytics is not initialized.'));
      return;
    }

    window.gtag('get', process.env.REACT_APP_GA4_ID || '', 'client_id', (clientId) => {
      if (clientId) {
        localStorage.setItem(LOCAL_STORAGE_KEY, clientId);
        resolve(clientId);
      } else {
        const cookieClientId = getClientIdFromCookies();

        if (cookieClientId) {
          localStorage.setItem(LOCAL_STORAGE_KEY, cookieClientId);
          resolve(cookieClientId);
        } else {
          reject(new Error('Client ID not found in cookies.'));
        }
      }
    });
  });
};

export const useGtagClientId = () => {
  const [clientId, setClientId] = useState<string | null>(null);

  useEffect(() => {
    getClientId()
      .then((id) => setClientId(id))
      .catch(() => {
        const fallbackId = getClientIdFromCookies();

        if (fallbackId) {
          setClientId(fallbackId);
        } else {
          console.error('Unable to retrieve client ID.');
        }
      });
  }, []);

  return {
    gtagClientId: clientId,
  };
};
