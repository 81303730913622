import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { ScrollToTop, ThemeColor } from '../components';
import { AnalyticProvider, ModalContextProvider, ToastContextProvider } from '../contexts';
import { InitialAnalytics } from './initial-analytics';
import { IS_DEV, USER_DATA } from '../constants/common';
import { PaymentSuccessMiddleware } from '../middlewares/payment-success/payment-success';
import { RouterProvider } from '../routes/RouterProvider';
import { FullscreenLoader } from '../components/fullscreen-loader';
import { getLSValue } from '../utils/locale-storage';
import { UserLocalStorageData } from '../types/user';
import { useUserTracking } from '../hooks/use-user-tracking';

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
  subscribeToChanges: true,
  onFeatureUsage: (featureKey, result?: { value?: string }) => {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('[Feature]', { featureKey, result: result?.value });
    }
  },
});

growthbook.init();

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const { trackUserData } = useUserTracking();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await trackUserData();
      } catch (e: unknown) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userLSData?.user_id) {
        return;
      }

      await growthbook.setAttributes({
        user_id: userLSData.user_id,
        device_id: window.navigator.userAgent,
        device_language: window.navigator.language?.toLowerCase() || 'en',
        browser: window.navigator.userAgent,
        url: window.location.host,
      });
    })();
  }, [userLSData]);

  return (
    <>
      {isLoading ? (
        <FullscreenLoader />
      ) : (
        <GrowthBookProvider growthbook={growthbook}>
          <AnalyticProvider>
            <BrowserRouter>
              <InitialAnalytics>
                <PaymentSuccessMiddleware>
                  <ModalContextProvider>
                    <ToastContextProvider>
                      <ScrollToTop />
                      <ThemeColor />
                      <RouterProvider />
                    </ToastContextProvider>
                  </ModalContextProvider>
                </PaymentSuccessMiddleware>
              </InitialAnalytics>
            </BrowserRouter>
          </AnalyticProvider>
        </GrowthBookProvider>
      )}
    </>
  );
}

export default App;
