import { useCallback } from 'react';
import { isEqual } from 'lodash';

import useQueryParams from './use-query-params';
import { getLSValue, setLSValue } from '../utils/locale-storage';
import { cookieManager } from '../services/cookies';
import { UserLocalStorageData } from '../types/user';
import { USER_DATA } from '../constants/common';
import { ipInfoService } from '../services/ip';
import { webApiService } from '../services/web-api';
import userDataApiService from '../services/web-api/user-data-api';

const FB_UTM_SOURCES = ['facebook', 'meta'];

export const useUserTracking = () => {
  const { getQueryParams, updateQueryParams } = useQueryParams();

  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const trackUserData = useCallback(async () => {
    const queryParams = getQueryParams();

    let adsData: Record<string, string> = {
      channel: (queryParams?.utm_source as string) || '',
      click_id: (queryParams?.clickid as string) || '',
    };

    if (FB_UTM_SOURCES.includes((queryParams?.utm_source as string)?.toLowerCase())) {
      const [fbpid, fbclid] = await Promise.all([
        cookieManager.waitForCookie('_fbp'),
        cookieManager.waitForCookie('_fbc'),
      ]);

      adsData = {
        channel: (queryParams?.utm_source as string) || '',
        pixel_id: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
        fbc: fbclid || (queryParams.fbclid as string) || '',
        fbp: fbpid || (queryParams.fbp as string) || '',
      };
    }

    if (isEqual(userLSData?.ads_data || {}, adsData) && userLSData?.user_id) {
      if (!queryParams?.customer_id) {
        updateQueryParams({ customer_id: userLSData.user_id });
      }
      return; // means we have the same user
    }

    if (queryParams?.customer_id) {
      const currentUser = await webApiService.getCustomerById(String(queryParams.customer_id));

      setLSValue(USER_DATA, {
        user_id: queryParams.customer_id,
        ads_data: adsData,
        ...(currentUser?.contactEmail ? { email: currentUser?.contactEmail } : {}),
      });
      return; // means we've already tracked user data (at compliance) and passed customer_id at query param
    }

    const userIp = await ipInfoService.getInfo();
    const userAgent = window.navigator.userAgent;

    const newUser = await webApiService.createCustomer({
      install: 'web',
    });

    const collectedData = {
      event_name: 'page_load',
      web_app_id: 'imagin8',
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      customer_id: newUser.customerId,
      ip: userIp.ip || '',
      country: userIp.country || '',
      zip: userIp.postal || '',
      city: userIp.city || '',
      ads_network_data: [adsData],
    };

    setLSValue(USER_DATA, { user_id: newUser.customerId, ads_data: adsData });
    updateQueryParams({ customer_id: newUser.customerId });
    await userDataApiService.loadCustomerTracking(collectedData);
  }, [getQueryParams, updateQueryParams, userLSData?.ads_data, userLSData?.user_id]);

  return { trackUserData };
};
