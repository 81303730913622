import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getLSValue, setLSValue } from '../utils/locale-storage';
import { QUIZ_FINISHED, SURVEY_STEPS_DATA, USER_DATA } from '../constants/common';
import { UserLocalStorageData } from '../types/user';
import { RouterBuilder } from './RouterBuilder';

interface Props {
  Component: FC;
}

export const ProtectedRoute: FC<Props> = ({ Component }) => {
  const isQuizFinished = getLSValue(QUIZ_FINISHED, true) as boolean;
  const userData = (getLSValue(USER_DATA, true) as UserLocalStorageData) || {};
  const location = useLocation();

  if (!isQuizFinished) {
    setLSValue(SURVEY_STEPS_DATA, []);

    return <Navigate to={`${RouterBuilder.getDefaultPublicRoutePath()}${location.search}`} replace />;
  }

  // TODO! Yura. Ask about protected redirection
  if (!userData.email) {
    return <Navigate to={`${RouterBuilder.getDefaultPublicRoutePath()}${location.search}`} replace />;
  }

  return <Component />;
};
